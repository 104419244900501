import React from 'react'

import Seo from '@/components/Seo'
import Layout from '@/components/Layout'
import Header from '@/components/Header'
/* import Transition from '@/components/Transition' */

/* import { IconLock } from '@/icons' */

import HERO from '../images/hero_servicios.jpg'

import NAVES_1 from '../images/naves_1.jpg'
import NAVES_2 from '../images/naves_2.jpg'
import NAVES_3 from '../images/naves_3.jpg'
import NAVES_4 from '../images/naves_4.jpg'

import AREA_1 from '../images/area_1.jpg'
import AREA_2 from '../images/area_2.jpg'
import AREA_3 from '../images/area_3.jpg'
import AREA_4 from '../images/area_4.jpg'
import AREA_5 from '../images/area_5.jpg'
import AREA_6 from '../images/area_6.jpg'
import AREA_7 from '../images/area_7.jpg'
import AREA_8 from '../images/area_8.jpg'
import AREA_9 from '../images/area_9.jpg'

import villa1 from '../images/villa1.jpeg'
import villa2 from '../images/villa2.jpeg'
import villa3 from '../images/villa3.jpeg'
import villa4 from '../images/villa4.jpeg'
import villa5 from '../images/villa5.jpeg'
import IconCheckSquare from '@/icons/IconCheckSquare'
import { Navigation, Pagination, Autoplay } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

const Servicios = () => {
  /* const [tab, setTab] = useState(1) */

  return (
    <Layout pause>
      <Seo title="Servicios"
        description='Almacen Rivera cuenta con almacenes en Lurin y  Villa el Salvador' />

      <Header
        image={HERO}
        title="Servicios"
        className="my-10 md:my-20 py-14 md:py-20"
        desc={'Almacen Rivera cuenta con almacenes en Lurin y Villa el Salvador'}
      />

      {/* <div className="flex flex-wrap justify-between gap-3 max-w-6xl mx-auto mb-14 md:mb-20">
        <div data-aos="flip-left" className="w-full max-w-xs">
          <div className="bg-gray-100 p-6 rounded-lg">
            <img
              className="h-40 rounded w-full object-cover object-center mb-6"
              src="https://dummyimage.com/720x400"
              alt="content"
            />
            <h3 className="tracking-widest text-red-500 text-xs font-medium title-font">
              SUBTITLE
            </h3>
            <h2 className="text-lg text-gray-900 font-medium title-font mb-4">
              Servicio 1
            </h2>
            <p className="leading-relaxed text-base">
              Fingerstache flexitarian street art 8-bit waistcoat. Distillery
              hexagon disrupt edison bulbche.
            </p>
          </div>
        </div>
        <div className="w-full max-w-xs">
          <div data-aos="flip-left" className="bg-gray-100 p-6 rounded-lg">
            <img
              className="h-40 rounded w-full object-cover object-center mb-6"
              src="https://dummyimage.com/721x401"
              alt="content"
            />
            <h3 className="tracking-widest text-red-500 text-xs font-medium title-font">
              SUBTITLE
            </h3>
            <h2 className="text-lg text-gray-900 font-medium title-font mb-4">
              Servicio 2
            </h2>
            <p className="leading-relaxed text-base">
              Fingerstache flexitarian street art 8-bit waistcoat. Distillery
              hexagon disrupt edison bulbche.
            </p>
          </div>
        </div>
        <div className="w-full max-w-xs">
          <div data-aos="flip-left" className="bg-gray-100 p-6 rounded-lg">
            <img
              alt="content"
              src="https://dummyimage.com/722x402"
              className="h-40 rounded w-full object-cover object-center mb-6"
            />
            <h3 className="tracking-widest text-red-500 text-xs font-medium title-font">
              SUBTITLE
            </h3>
            <h2 className="text-lg text-gray-900 font-medium title-font mb-4">
              Servicio 3
            </h2>
            <p className="leading-relaxed text-base">
              Fingerstache flexitarian street art 8-bit waistcoat. Distillery
              hexagon disrupt edison bulbche.
            </p>
          </div>
        </div>
      </div> */}
      <div className="max-w-6xl  mx-auto px-5">
        <h2 className='title-7 sm:title-5  md:title-5'>LURIN</h2>
      </div>

      <div className="md:grid md:grid-cols-12 gap-14 max-w-6xl mx-auto  px-8 mb-8">
        {/* Content */}
        <div
          data-aos="fade-right"
          className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-6 lg:col-span-5 mt-6 "
        >
          <div data-aos="flip-left" className="w-full bg-gray-100 lg:max-w-xs mx-auto shadow-xl  rounded-lg mb-10 lg:h-full">
            <div className=" p-6 rounded-lg flex flex-col gap-y-3">
              <h3 className="md:text-lg text-gray-900 font-medium title-font text-base mb-4 lg:mb-8 text-center">
                Naves Industriales de 825 m2
              </h3>
              <ul className='flex flex-col gap-y-3 md:gap-y-4'>
                <li className='flex items-center gap-x-3 text-sm '>
                  <div className="flex justify-center items-center w-4 h-4  flex-shrink-0 ml-3">
                    <IconCheckSquare className="w-3 h-3 " fill="#82C91E" />
                  </div> Altura al pico más alto: 10 m
                </li>
                <li className='flex items-center gap-x-3 text-sm '>
                  <div className="flex justify-center items-center w-4 h-4  flex-shrink-0 ml-3">
                    <IconCheckSquare className="w-3 h-3 " fill="#82C91E" />
                  </div> Largo: 33m
                </li>
                <li className='flex items-center gap-x-3 text-sm '>
                  <div className="flex justify-center items-center w-4 h-4  flex-shrink-0 ml-3">
                    <IconCheckSquare className="w-3 h-3 " fill="#82C91E" />
                  </div> Ancho:25m
                </li>
                <li className='flex items-center gap-x-3 text-sm '>
                  <div className="flex justify-center items-center w-4 h-4  flex-shrink-0 ml-3">
                    <IconCheckSquare className="w-3 h-3 " fill="#82C91E" />
                  </div> Seguridad las 24 horas del día.
                </li>
              </ul>
            </div>
          </div>
          {/* Tabs buttons */}
          {/* <div className="mb-8 md:mb-0">
            <a
              className={`flex items-center text-lg p-5 rounded border transition duration-300 ease-in-out mb-3 ${tab !== 1
                ? 'bg-white shadow-md border-gray-200 hover:shadow-lg'
                : 'bg-gray-200 border-transparent'
                }`}
              href="#0"
              onClick={(e) => {
                e.preventDefault()
                setTab(1)
              }}
            >
              <div>
                <div className="font-bold leading-snug tracking-tight mb-1">
                  Servicio 1
                </div>
                <div className="text-gray-600">
                  Duis aute irure dolor in reprehenderit in voluptate velit esse
                  cillum dolore pariatur.
                </div>
              </div>
              <div className="flex justify-center items-center w-8 h-8 bg-white rounded-full shadow flex-shrink-0 ml-3">
                <IconLock className="w-3 h-3" />
              </div>
            </a>
            <a
              className={`flex items-center text-lg p-5 rounded border transition duration-300 ease-in-out mb-3 ${tab !== 2
                ? 'bg-white shadow-md border-gray-200 hover:shadow-lg'
                : 'bg-gray-200 border-transparent'
                }`}
              href="#0"
              onClick={(e) => {
                e.preventDefault()
                setTab(2)
              }}
            >
              <div>
                <div className="font-bold leading-snug tracking-tight mb-1">
                  Servicio 2
                </div>
                <div className="text-gray-600">
                  Duis aute irure dolor in reprehenderit in voluptate velit esse
                  cillum dolore pariatur.
                </div>
              </div>
              <div className="flex justify-center items-center w-8 h-8 bg-white rounded-full shadow flex-shrink-0 ml-3">
                <svg
                  className="w-3 h-3 fill-current"
                  viewBox="0 0 12 12"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11.854.146a.5.5 0 00-.525-.116l-11 4a.5.5 0 00-.015.934l4.8 1.921 1.921 4.8A.5.5 0 007.5 12h.008a.5.5 0 00.462-.329l4-11a.5.5 0 00-.116-.525z"
                    fillRule="nonzero"
                  />
                </svg>
              </div>
            </a>
            <a
              className={`flex items-center text-lg p-5 rounded border transition duration-300 ease-in-out mb-3 ${tab !== 3
                ? 'bg-white shadow-md border-gray-200 hover:shadow-lg'
                : 'bg-gray-200 border-transparent'
                }`}
              href="#0"
              onClick={(e) => {
                e.preventDefault()
                setTab(3)
              }}
            >
              <div>
                <div className="font-bold leading-snug tracking-tight mb-1">
                  Servicio 3
                </div>
                <div className="text-gray-600">
                  Duis aute irure dolor in reprehenderit in voluptate velit esse
                  cillum dolore pariatur.
                </div>
              </div>
              <div className="flex justify-center items-center w-8 h-8 bg-white rounded-full shadow flex-shrink-0 ml-3">
                <svg
                  className="w-3 h-3 fill-current"
                  viewBox="0 0 12 12"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11.334 8.06a.5.5 0 00-.421-.237 6.023 6.023 0 01-5.905-6c0-.41.042-.82.125-1.221a.5.5 0 00-.614-.586 6 6 0 106.832 8.529.5.5 0 00-.017-.485z"
                    fill="#191919"
                    fillRule="nonzero"
                  />
                </svg>
              </div>
            </a>
          </div> */}
        </div>

        <div
          data-aos="zoom-y-out"
          className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-6 lg:col-span-7 mb-8 md:mb-0 md:order-1 grid place-items-center"
        >
          <div className="aspect-w-16 aspect-h-9 w-full grid">
            <Swiper
              spaceBetween={-3}
              slidesPerView={1}
              navigation
              autoplay={{ delay: 7000 }}
              pagination={{ clickable: true }}
              modules={[Navigation, Pagination, Autoplay]}
            >
              <SwiperSlide>
                <div className="relative w-full aspect-w-16 aspect-h-9 rounded-lg overflow-hidden">
                  <img
                    src={NAVES_1}
                    alt="Features bg"
                    className="absolute inset-0 w-full h-full"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="relative w-full aspect-w-16 aspect-h-9 rounded-lg overflow-hidden">
                  <img
                    src={NAVES_2}
                    alt="Features bg"
                    className="absolute inset-0 w-full h-full"
                  />
                </div>

              </SwiperSlide>
              <SwiperSlide>
                <div className="relative w-full aspect-w-16 aspect-h-9 rounded-lg overflow-hidden">
                  <img
                    src={NAVES_3}
                    alt="Features bg"
                    className="absolute inset-0 w-full h-full"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="relative w-full aspect-w-16 aspect-h-9 rounded-lg overflow-hidden">
                  <img
                    src={NAVES_4}
                    alt="Features bg"
                    className="absolute inset-0 w-full h-full"
                  />
                </div>

              </SwiperSlide>
              {/* <SwiperSlide>
                <div className="relative w-full aspect-w-16 aspect-h-9 rounded-lg overflow-hidden">
                  <img
                    src={SOLUCION_3}
                    alt="Features bg"
                    className="absolute inset-0 w-full h-full"
                  />
                </div>
              </SwiperSlide> */}
            </Swiper>

            {/* <Transition
              show={tab === 1}
              appear={true}
              className="w-full"
              enter="transition ease-in-out duration-700 transform order-first"
              enterStart="opacity-0 translate-y-16"
              enterEnd="opacity-100 translate-y-0"
              leave="transition ease-in-out duration-300 transform absolute"
              leaveStart="opacity-100 translate-y-0"
              leaveEnd="opacity-0 -translate-y-16"
            >
              <div className="relative w-full aspect-w-16 aspect-h-9 rounded-lg overflow-hidden">
                <img
                  src={SOLUCION_1}
                  alt="Features bg"
                  className="absolute inset-0 w-full h-full"
                />
              </div>
            </Transition> 
            <Transition
              show={tab === 2}
              appear={true}
              className="w-full"
              enter="transition ease-in-out duration-700 transform order-first"
              enterStart="opacity-0 translate-y-16"
              enterEnd="opacity-100 translate-y-0"
              leave="transition ease-in-out duration-300 transform absolute"
              leaveStart="opacity-100 translate-y-0"
              leaveEnd="opacity-0 -translate-y-16"
            >
              <div className="relative w-full aspect-w-16 aspect-h-9 rounded-lg overflow-hidden">
                <img
                  src={SOLUCION_2}
                  alt="Features bg"
                  className="absolute inset-0 w-full h-full"
                />
              </div>
            </Transition>           
            <Transition
              show={tab === 3}
              appear={true}
              className="w-full"
              enter="transition ease-in-out duration-700 transform order-first"
              enterStart="opacity-0 translate-y-16"
              enterEnd="opacity-100 translate-y-0"
              leave="transition ease-in-out duration-300 transform absolute"
              leaveStart="opacity-100 translate-y-0"
              leaveEnd="opacity-0 -translate-y-16"
            >
              <div className="relative w-full aspect-w-16 aspect-h-9 rounded-lg overflow-hidden">
                <img
                  src={SOLUCION_3}
                  alt="Features bg"
                  className="absolute inset-0 w-full h-full"
                />
              </div>
            </Transition> */}
          </div>
        </div>
      </div>

      <div className="md:grid md:grid-cols-12 gap-14 max-w-6xl mx-auto pb-14 md:pb-20 px-8">
        {/* Content */}
        <div
          data-aos="fade-right"
          className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-6 lg:col-span-5 mt-6 "
        >
          <div data-aos="flip-left" className="w-full bg-gray-100 lg:max-w-xs mx-auto shadow-xl  rounded-lg mb-10 lg:h-full">
            <div className=" p-6 rounded-lg flex flex-col gap-y-3">
              <h3 className="md:text-lg text-gray-900 font-medium title-font text-base mb-4 lg:mb-8 text-center">
                Áreas Libres de 100m2 a más
              </h3>
              <h4 className=' text-gray-900 font-medium title-font text-xs mb-4 lg:mb-8 '>Servicio de alquiler para maquinaria:</h4>
              <ul className='flex flex-col gap-y-3 md:gap-y-4'>
                <li className='flex items-center gap-x-3 text-sm '>
                  <div className="flex justify-center items-center w-4 h-4  flex-shrink-0 ml-3">
                    <IconCheckSquare className="w-3 h-3 " fill="#82C91E" />
                  </div> Maquinaria pesada
                </li>
                <li className='flex items-center gap-x-3 text-sm '>
                  <div className="flex justify-center items-center w-4 h-4  flex-shrink-0 ml-3">
                    <IconCheckSquare className="w-3 h-3 " fill="#82C91E" />
                  </div> La construcción
                </li>
                <li className='flex items-center gap-x-3 text-sm '>
                  <div className="flex justify-center items-center w-4 h-4  flex-shrink-0 ml-3">
                    <IconCheckSquare className="w-3 h-3 " fill="#82C91E" />
                  </div> Almacén simple
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div
          data-aos="zoom-y-out"
          className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-6 lg:col-span-7 mb-8 md:mb-0 md:order-1 grid place-items-center"
        >
          <div className="aspect-w-16 aspect-h-9 w-full grid">
            <Swiper
              spaceBetween={-3}
              slidesPerView={1}
              navigation
              autoplay={{ delay: 7000 }}
              pagination={{ clickable: true }}
              modules={[Navigation, Pagination, Autoplay]}
            >
              <SwiperSlide>
                <div className="relative w-full aspect-w-16 aspect-h-9 rounded-lg overflow-hidden">
                  <img
                    src={AREA_1}
                    alt="Features bg"
                    className="absolute inset-0 w-full h-full"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="relative w-full aspect-w-16 aspect-h-9 rounded-lg overflow-hidden">
                  <img
                    src={AREA_2}
                    alt="Features bg"
                    className="absolute inset-0 w-full h-full"
                  />
                </div>

              </SwiperSlide>
              <SwiperSlide>
                <div className="relative w-full aspect-w-16 aspect-h-9 rounded-lg overflow-hidden">
                  <img
                    src={AREA_3}
                    alt="Features bg"
                    className="absolute inset-0 w-full h-full"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="relative w-full aspect-w-16 aspect-h-9 rounded-lg overflow-hidden">
                  <img
                    src={AREA_4}
                    alt="Features bg"
                    className="absolute inset-0 w-full h-full"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="relative w-full aspect-w-16 aspect-h-9 rounded-lg overflow-hidden">
                  <img
                    src={AREA_5}
                    alt="Features bg"
                    className="absolute inset-0 w-full h-full"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="relative w-full aspect-w-16 aspect-h-9 rounded-lg overflow-hidden">
                  <img
                    src={AREA_6}
                    alt="Features bg"
                    className="absolute inset-0 w-full h-full"
                  />
                </div>

              </SwiperSlide>
              <SwiperSlide>
                <div className="relative w-full aspect-w-16 aspect-h-9 rounded-lg overflow-hidden">
                  <img
                    src={AREA_7}
                    alt="Features bg"
                    className="absolute inset-0 w-full h-full"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="relative w-full aspect-w-16 aspect-h-9 rounded-lg overflow-hidden">
                  <img
                    src={AREA_8}
                    alt="Features bg"
                    className="absolute inset-0 w-full h-full"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="relative w-full aspect-w-16 aspect-h-9 rounded-lg overflow-hidden">
                  <img
                    src={AREA_9}
                    alt="Features bg"
                    className="absolute inset-0 w-full h-full"
                  />
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>

      </div>

      <div className="max-w-6xl  mx-auto px-5">
        <h2 className='title-7 sm:title-5  md:title-5'>VILLA EL SALVADOR</h2>
      </div>

      <div className="md:grid md:grid-cols-12 gap-14 max-w-6xl mx-auto pb-14 md:pb-20 px-8">
        {/* Content */}
        <div
          data-aos="fade-right"
          className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-6 lg:col-span-5 mt-6 "
        >
          <div data-aos="flip-left" className="w-full bg-gray-100 lg:max-w-xs mx-auto shadow-xl  rounded-lg mb-10 lg:h-full">
            <div className=" p-6 rounded-lg flex flex-col gap-y-3">
              <h3 className="md:text-lg text-gray-900 font-medium title-font text-base mb-4 lg:mb-8 text-center">
                Naves Industriales de 1750m2
              </h3>
              <ul className='flex flex-col gap-y-3 md:gap-y-4'>
                <li className='flex items-center gap-x-3 text-sm '>
                  <div className="flex justify-center items-center w-4 h-4  flex-shrink-0 ml-3">
                    <IconCheckSquare className="w-3 h-3 " fill="#82C91E" />
                  </div> Altura al pico más alto:10m
                </li>
                <li className='flex items-center gap-x-3 text-sm '>
                  <div className="flex justify-center items-center w-4 h-4  flex-shrink-0 ml-3">
                    <IconCheckSquare className="w-3 h-3 " fill="#82C91E" />
                  </div> Largo: 66.70 m
                </li>
                <li className='flex items-center gap-x-3 text-sm '>
                  <div className="flex justify-center items-center w-4 h-4  flex-shrink-0 ml-3">
                    <IconCheckSquare className="w-3 h-3 " fill="#82C91E" />
                  </div> Ancho:26.25 m
                </li>
                <li className='flex items-center gap-x-3 text-sm '>
                  <div className="flex justify-center items-center w-4 h-4  flex-shrink-0 ml-3">
                    <IconCheckSquare className="w-3 h-3 " fill="#82C91E" />
                  </div> Espacios: Oficinas y servicios higiénicos
                </li>
                <li className='flex items-center gap-x-3 text-sm '>
                  <div className="flex justify-center items-center w-4 h-4  flex-shrink-0 ml-3">
                    <IconCheckSquare className="w-3 h-3 " fill="#82C91E" />
                  </div> Seguridad las 24 horas del día.
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div
          data-aos="zoom-y-out"
          className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-6 lg:col-span-7 mb-8 md:mb-0 md:order-1 grid place-items-center"
        >
          <div className="aspect-w-16 aspect-h-9 w-full grid">
            <Swiper
              spaceBetween={-3}
              slidesPerView={1}
              navigation
              autoplay={{ delay: 7000 }}
              pagination={{ clickable: true }}
              modules={[Navigation, Pagination, Autoplay]}
            >
              <SwiperSlide>
                <div className="relative w-full aspect-w-16 aspect-h-9 rounded-lg overflow-hidden">
                  <img
                    src={villa1}
                    alt="Features bg"
                    className="absolute inset-0 w-full h-full"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="relative w-full aspect-w-16 aspect-h-9 rounded-lg overflow-hidden">
                  <img
                    src={villa2}
                    alt="Features bg"
                    className="absolute inset-0 w-full h-full"
                  />
                </div>

              </SwiperSlide>
              <SwiperSlide>
                <div className="relative w-full aspect-w-16 aspect-h-9 rounded-lg overflow-hidden">
                  <img
                    src={villa3}
                    alt="Features bg"
                    className="absolute inset-0 w-full h-full"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="relative w-full aspect-w-16 aspect-h-9 rounded-lg overflow-hidden">
                  <img
                    src={villa4}
                    alt="Features bg"
                    className="absolute inset-0 w-full h-full"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="relative w-full aspect-w-16 aspect-h-9 rounded-lg overflow-hidden">
                  <img
                    src={villa5}
                    alt="Features bg"
                    className="absolute inset-0 w-full h-full"
                  />
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>

      </div>
    </Layout>
  )
}

export default Servicios
